@import "index", "highlight";

.title {
  font-size: 1.4em; }

.archive {
  font-size: 1.1em; }

.archive time {
  display: inline-block;
  min-width: 10ch;
  margin: 0 .2em; }



.icon {
  height: 1em;
  width: 1em;
  fill: currentColor;
  transition: .2s color;
  vertical-align: middle;
  margin-bottom: .15em; }

a:hover .icon {
  color: reduce(100); }





.more {
  margin: 2em 0 1em; }

.more a {
  border-radius: 2px;
  border: 1.5px solid $link-color;
  padding: .4em .8em;
  transition: .2s background, .2s color; }

.more a:hover {
  color: #fff;
  background: $link-color;
  text-decoration: inherit; }


